import React from "react";
import { PortableText } from "@portabletext/react";
import "./BlogPost.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import { FiCalendar, FiUser } from "react-icons/fi";
import SidebarTag from "../../components/blog/SidebarTag";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

const BlogDetailsContent = ({ pageContext, data }) => {
  const { blogPost: post } = pageContext;
  const { caption, ...posterData } = post.poster;
  const publishedOn = new Date(post.publishedOn).toLocaleDateString("en-GB");
  return (
    <>
      <SEO title={`${post.title}`} />
      <Layout>
        <div className="post-page-banner rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content text-center">
                  <div className="page-title">
                    <h1 className="theme-gradient">{post.title}</h1>
                    <div className="mt-4" style={{ fontSize: "1.5em" }}>
                      {post.intro}
                    </div>
                  </div>
                  <ul className="rn-meta-list">
                    <li>
                      <FiUser />
                      <Link
                        to={`/articles/author/${post.author.handle.current}`}
                      >
                        {post.author.name}
                      </Link>
                    </li>
                    <li>
                      <FiCalendar />
                      {publishedOn}
                    </li>
                  </ul>
                  <div className="thumbnail alignwide mt--60">
                    <SanityImage
                      {...posterData}
                      alt={caption}
                      className="w-100 radius"
                      width="1280"
                      height="800"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-details-content blogPost__content pt--60 rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="content">
                  <PortableText value={post._rawContent}></PortableText>

                  <div className="category-meta">
                    <span className="text">Tags:</span>
                    <SidebarTag tags={post.tags} />
                  </div>

                  {/*<div className="rn-comment-form pt--60">*/}
                  {/*  <div className="comment-respond">*/}
                  {/*    <h4 className="title mb--40">Leave a Reply</h4>*/}
                  {/*    <Comment url="" id={post.id} title={post.title} />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogDetailsContent;
