import React from "react";
import { flatDeep, slugify, containsObject } from "../../doob/utils";
import BlogListData from "../../doob/data/blog/BlogList.json";
import { Link } from "gatsby";

const SidebarTag = ({ tags }) => {
  return (
    <ul className="tagcloud">
      {tags.map((tag) => {
        return (
          <Link key={tag.slug} to={`/articles/tag/${tag.slug}`}>
            {tag.text}
          </Link>
        );
      })}
    </ul>
  );
};

export default SidebarTag;
